"use strict";

var main = {
    loadWebFont: function(){
        WebFont.load({
            google: {
                families: ['Poppins:300,400,600&display=swap&subset=latin-ext']
            }
        });
    },
    navigation: function(){
        var navigationButton    = $(".navigation__toggle"),
            sidebar             = $(".aside");
        
        navigationButton.off().on({
            click: function () {
                $(this).toggleClass("is-active");
                sidebar.toggleClass("is-active");
            }
        })
    },
    heroCarousel: function(){
        if(document.querySelector(".hero")){
            var heroSwiper = new Swiper ('.swiper-container', {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination'
                }
            })
        }
    },
    accordionCreator: function(){
        $(".accordion-content .item .item-head").off("click").on({
            click: function (event) {
                event.preventDefault();

                var _this		= $(this);

                var title 		= _this,
                    container 	= _this.closest(".accordion-content"),
                    item 		= _this.closest(".item"),
                    content 	= $(">.item-content", item);


                function accordionMaker(){
                    if (title.hasClass("is-active")) {
                        title.removeClass("is-active");
                        content.removeClass("is-active");
                        content.stop().slideUp(300, "linear");
                    } else {
                        $(".item .item-head", container).removeClass("is-active");
                        $(".item .item-content", container).slideUp(300, "linear");
                        // Eğer tıklayınca diğerleri kapansın istiyorsan aktif et.

                        title.addClass("is-active");
                        content.addClass("is-active");
                        content.stop().slideDown(300, "linear", function () {
                            if (container.hasClass("scroll")) {
                                var _offsetTop = $("header").height();
                                var scrollTop = item.offset().top - _offsetTop;

                                $("html, body").stop().animate({
                                    scrollTop: scrollTop
                                }, 300);
                            }
                        });
                    }
                }

                function accordionMobileControl(){
                    if (window.matchMedia("(min-width: 992px)").matches) {
                        accordionMaker = null;
                        $(".item .item-head", container).removeClass("is-active");
                        $(".item .item-content", container).css("display", "");
                    } else {
                        accordionMaker();
                    }
                }


                if(container.hasClass("is-accordion-mobile")){
                    accordionMobileControl();
                    $(window).on({resize: function(){accordionMobileControl();}});
                } else{
                    accordionMaker();
                }
            }
        });
    },
    requestForm: function () {
        var requestRowButton    = $(".js-add-request-row");
        var template =  `
        <div class="form-row span-8-4">
            <div class="input-1">
                <label>
                    <span class="label">Teklif İstediğiniz Ürün</span>
                    <div class="input-group">
                        <input type="text" name="teklifUrun" required>
                    </div>
                </label>
            </div>
            <div class="input-1">
                <label>
                    <span class="label">Adet</span>
                    <div class="input-group">
                        <input type="number" name="teklifUrunAdet" required>
                    </div>
                </label>
            </div>
        </div>`;

        requestRowButton.off().on({
            click: function () {
                $(this).parents(".form-row").prepend(template);
            }
        });
    }
};


// Document.Ready
document.addEventListener("DOMContentLoaded", function() {
    main.loadWebFont();
    main.navigation();
    main.heroCarousel();
    main.accordionCreator();
    main.requestForm();
});